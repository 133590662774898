import React from "react";
import { toast } from "react-toastify";
import ContactApi from "../api/contact";

export class MainContactForm extends React.Component {
  constructor(props) {
    super(props);

    // The source of the contact
    this.request_type = "main";
    this.request_source_item = props.contact_source;

    // Classes for form fields
    this.defaultFormClasses = {
      fname: "form-control",
      lname: "form-control",
      company: "form-control",
      number: "form-control",
      email: "form-control",
      message: "form-control",
      agree: "form-check-input",
      agree_txt: "form-check-label",
    };

    this.stateFieldsClassProps = {
      fname: "field_class_fname",
      lname: "field_class_lname",
      company: "field_class_company",
      number: "field_class_number",
      email: "field_class_email",
      message: "field_class_message",
      agree: "field_class_agree",
      agree_txt: "field_class_agree_txt",
      information: "field_class_information",
    };

    this.state = {
      lname: "",
      fname: "",
      company: "",
      email: "",
      number: "",
      message: "",
      agree: false,
      [this.stateFieldsClassProps["fname"]]: this.defaultFormClasses["fname"],
      [this.stateFieldsClassProps["lname"]]: this.defaultFormClasses["lname"],
      [this.stateFieldsClassProps["company"]]:
        this.defaultFormClasses["company"],
      [this.stateFieldsClassProps["number"]]: this.defaultFormClasses["number"],
      [this.stateFieldsClassProps["email"]]: this.defaultFormClasses["email"],
      [this.stateFieldsClassProps["message"]]:
        this.defaultFormClasses["message"],
      [this.stateFieldsClassProps["agree"]]: this.defaultFormClasses["agree"],
      [this.stateFieldsClassProps["agree_txt"]]:
        this.defaultFormClasses["agree_txt"],
      [this.stateFieldsClassProps["information"]]: "form-info-label",
      formClasses: { ...this.defaultFormClasses },
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    // Form fields i18n properties
    this.name_label = props.name_label;
    this.name_ph = props.name_ph;
    this.fname_label = props.fname_label;
    this.fname_ph = props.fname_ph;
    this.company_label = props.company_label;
    this.company_ph = props.company_ph;
    this.phone_label = props.phone_label;
    this.phone_ph = props.phone_ph;
    this.email_label = props.email_label;
    this.email_ph = props.email_ph;
    this.message_label = props.message_label;
    this.message_ph = props.message_ph;
    this.terms_msg_p1 = props.terms_msg_p1;
    this.terms_msg_p2 = props.terms_msg_p2;
    this.smsg_description_communications =
      props.smsg_description_communications;
    this.send_btn = props.send_btn;
    this.required_field_msg = props.form_required_field_msg;

    // notifications messages
    this.notif_messages = {
      at_least_one_error: props.notif_at_least_one_error_msg,
      sending_form_info: props.notif_sending_form_msg,
      message_sent_success: props.success_message_sent_msg,
    };

    // backend field validation error messages
    this.backend_validation_unkown_error = props.notif_unknown_error_msg;
    this.backend_validation_errors = {
      email_wrong_value: props.notif_email_validation_error_msg, // "The email address is wrong!",
      lname_wrong_value: props.notif_lname_validation_error_msg, // "Your lastname must be a valid string",
      fname_wrong_value: props.notif_fname_validation_error_msg, // "Your firstname must be a valid string",
      company_wrong_value: props.notif_company_validation_error_msg, // "The value for your company's name seems wrong",
      number_wrong_value: props.notif_number_validation_error_msg, // "Is this phone number really valid ?",
      message_wrong_value: props.notif_message_validation_error_msg, // "Don't be shy please send a message between 5 and 500 characters!"
    };
  }

  resetAllFields() {
    const blankValues = {
      lname: "",
      fname: "",
      company: "",
      email: "",
      number: "",
      message: "",
      agree: false,
    };
    this.setState(blankValues, () => {
      return true;
    });
  }

  resetErrorFields() {
    const keysList = Object.keys(this.stateFieldsClassProps);
    for (const idx in keysList) {
      const key = keysList[idx];
      const update = this.defaultFormClasses[key];
      this.setState({ [this.stateFieldsClassProps[key]]: update });
    }
  }

  handleChange(event) {
    let update = {};
    this.resetErrorFields();

    switch (event.target.name) {
      case "lname":
        update[event.target.name] = event.target.value;
        this.setState(update, this.checkFormLastName);
        break;
      case "fname":
        update[event.target.name] = event.target.value;
        this.setState(update, this.checkFormFirstName);
        break;
      case "company":
        update[event.target.name] = event.target.value;
        this.setState(update, this.checkFormCompany);
        break;
      case "number":
        update[event.target.name] = event.target.value;
        this.setState(update, this.checkForNumber);
        break;
      case "email":
        update[event.target.name] = event.target.value;
        this.setState(update, this.checkForEmail);
        break;
      case "message":
        update[event.target.name] = event.target.value;
        this.setState(update, this.checkForMessage);
        break;
      case "agree":
        update[event.target.name] = event.target.checked;
        this.setState(update, this.checkForAgree);
        break;
    }
  }

  checkFormFirstName() {
    const bValidation = this.state.fname.trim().length > 0;
    // if (!bValidation){
    //     const update = `${this.defaultFormClasses["fname"]} error`
    //     this.setState({field_class_fname: update})
    // }

    return bValidation;
  }

  checkFormCompany() {
    const bValidation = this.state.company.trim().length > 0;

    return bValidation;
  }

  checkFormLastName() {
    const bValidation = this.state.lname.trim().length > 0;
    if (!bValidation) {
      const update = `${this.defaultFormClasses["lname"]} error`;
      this.setState({ field_class_lname: update });
    }

    return bValidation;
  }

  checkForNumber() {
    const bValidation = this.state.number.trim().length > 0;
    //   if (!bValidation){
    //     const update = `${this.defaultFormClasses["number"]} error`
    //     this.setState({field_class_number: update})
    //   }
    return bValidation;
  }

  checkForEmail() {
    const bValidation = this.state.email.trim().length > 0;
    if (!bValidation) {
      const update = `${this.defaultFormClasses["email"]} error`;
      this.setState({ field_class_email: update });
    }
    return bValidation;
  }

  checkForMessage() {
    const bValidation = this.state.message.trim().length > 0;
    if (!bValidation) {
      const update = `${this.defaultFormClasses["message"]} error`;
      this.setState({ field_class_message: update });
    }
    return bValidation;
  }

  checkForAgree() {
    const bValidation = this.state.agree;
    if (!bValidation) {
      const update = `${this.defaultFormClasses["agree"]} error`;
      this.setState({ field_class_agree: update });

      const txt_update = `${this.defaultFormClasses["agree_txt"]} error`;
      this.setState({ field_class_agree_txt: txt_update });
    }
    return bValidation;
  }

  checkFormValues() {
    const validation =
      this.checkFormLastName() &
      // & this.checkFormFirstName()
      // & this.checkForNumber()
      this.checkForEmail() &
      this.checkForMessage() &
      this.checkForAgree();

    return Boolean(validation);
  }

  triggerErrorOnField(field) {
    const update = `${this.defaultFormClasses[field]} error`;
    this.setState({ [this.stateFieldsClassProps[field]]: update });
  }

  async handleSubmit(event) {
    event.preventDefault();
    if (this.checkFormValues()) {
      toast.info(this.notif_messages["sending_form_info"]);
      try {
        const response = await ContactApi.post("/joinus/", {
          email: this.state.email,
          lname: this.state.lname,
          fname: this.state.fname,
          company: this.state.company,
          number: this.state.number,
          message: this.state.message,
          reqtype: this.request_type,
          reqsource: this.request_source_item,
        });
        if (response.data.success) {
          this.resetErrorFields();
          this.resetAllFields();
          toast.success(this.notif_messages["message_sent_success"]);
        } else {
          toast.error(this.notif_messages["at_least_one_error"]);

          // Collecting errors from the backend
          if (response.data.errors.length > 0) {
            for (const backend_error in response.data.errors) {
              this.triggerErrorOnField(
                response.data.errors[backend_error].param,
              );

              // Do we know the error ?
              const known_backend_errors = Object.keys(
                this.backend_validation_errors,
              );
              if (
                known_backend_errors.includes(
                  response.data.errors[backend_error].msg,
                )
              ) {
                const customFieldErrMsg =
                  this.backend_validation_errors[
                    response.data.errors[backend_error].msg
                  ];
                toast.error(customFieldErrMsg);
              } else {
                toast.error(this.backend_validation_unkown_error);
              }
            }
          }
        }
      } catch (e) {
        toast.error(this.backend_validation_unkown_error);
      }
    } else {
      toast.error(this.notif_messages["at_least_one_error"]);
    }
  }

  render() {
    return (
      <>
        <form
          className="Inqueryform"
          noValidate="novalidate"
          onSubmit={this.handleSubmit}
        >
          <div className="form-group">
            <label htmlFor="exampleInputLname">
              {this.name_label}
              <span className="required_field">*</span>
            </label>
            <input
              type="text"
              className={this.state.field_class_lname}
              name="lname"
              id="exampleInputFname"
              placeholder={this.name_ph}
              value={this.state.lname}
              onChange={this.handleChange}
            />
            <label
              id="exampleInputLname-error"
              className="error"
              for="exampleInputLname"
              style={{
                display:
                  this.state.field_class_lname !=
                  this.defaultFormClasses["lname"]
                    ? "block"
                    : "none",
              }}
            >
              {this.required_field_msg}
            </label>
          </div>

          <div className="form-group">
            <label htmlFor="exampleInputFname">{this.fname_label}</label>
            <input
              type="text"
              className={this.state.field_class_fname}
              name="fname"
              id="exampleInputLname"
              placeholder={this.fname_ph}
              value={this.state.fname}
              onChange={this.handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="exampleInputCompany">{this.company_label}</label>
            <input
              type="text"
              className={this.state.field_class_company}
              name="company"
              id="exampleInputCompany"
              placeholder={this.company_ph}
              value={this.state.company}
              onChange={this.handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="exampleInputNumber">{this.phone_label}</label>
            <input
              type="tel"
              className={this.state.field_class_number}
              id="exampleInputNumber"
              name="number"
              placeholder={this.phone_ph}
              value={this.state.number}
              onChange={this.handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="exampleInputEmail1">
              {this.email_label}
              <span className="required_field">*</span>
            </label>
            <input
              type="email"
              className={this.state.field_class_email}
              id="exampleInputEmail1"
              name="email"
              placeholder={this.email_ph}
              value={this.state.email}
              onChange={this.handleChange}
            />
            <label
              id="exampleInputEmail-error"
              className="error"
              for="exampleInputEmail"
              style={{
                display:
                  this.state.field_class_email !=
                  this.defaultFormClasses["email"]
                    ? "block"
                    : "none",
              }}
            >
              {this.required_field_msg}
            </label>
          </div>

          <div className="form-group">
            <label htmlFor="exampleFormControlmessage">
              {this.message_label}
              <span className="required_field">*</span>
            </label>
            <textarea
              className={this.state.field_class_message}
              id="FormControlmessage"
              name="message"
              rows={3}
              placeholder={this.message_ph}
              defaultValue={""}
              value={this.state.message}
              onChange={this.handleChange}
            />
            <label
              id="exampleInputMessage-error"
              className="error"
              for="exampleInputMessage"
              style={{
                display:
                  this.state.field_class_message !=
                  this.defaultFormClasses["message"]
                    ? "block"
                    : "none",
              }}
            >
              {this.required_field_msg}
            </label>
          </div>

          <div className="form-info">
            <label className={this.state.field_class_information}>
              {this.terms_msg_p1} {this.terms_msg_p2}
            </label>
          </div>

          <div className="form-check">
            <span className={this.state.field_class_agree}>
              <input
                type="checkbox"
                id="exampleCheck1"
                name="agree"
                checked={this.state.agree}
                onChange={this.handleChange}
              />
              <span />
            </span>
            <label
              className={this.state.field_class_agree_txt}
              htmlFor="exampleCheck1"
            >
              {this.smsg_description_communications}
            </label>
          </div>

          <div className="form-group">
            <input
              type="submit"
              id="subbtn"
              className="btn"
              defaultValue={this.send_btn}
              value={this.send_btn}
            />
          </div>
        </form>
      </>
    );
  }
}
